<template>
  <div class="home">
    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">

          <ul class="nav nav-admin">
            <li>
              <router-link to="/account/overview">
                <i class="material-icons">dashboard</i>
                <span>Übersicht</span>
              </router-link>
            </li>
            <li class="active">
              <router-link to="/account/reservations">
                <i class="material-icons">today</i>
                <span>Reservierungen</span>
              </router-link>
            </li>
            <li>
              <router-link to="/account/payment_methods">
                <i class="material-icons">account_balance</i>
                <span>Bezahlmethoden</span>
              </router-link>
            </li>
            <li>
              <router-link to="/account/settings">
                <i class="material-icons">settings</i>
                <span>Einstellungen</span>
              </router-link>
            </li>
          </ul>

        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li>Reservierungen</li>
            </ul>
          </header>

          <table class="table" style="margin-top:20px">
            <thead>
              <tr>
                <th width="20%">Status</th>
                <th width="20%">Reserviert am</th>
                <th width="20%">Abholung<br>Rückgabe</th>
                <th width="20%">Fahrzeug</th>
                <th width="20%">Betrag</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="reservation in reservations" v-bind:key="reservation.id">
                <td>
                  <span v-if="reservation.status == 'booked'" class="badge badge-yellow">Offen</span>
                  <span v-if="reservation.status == 'canceled'" class="badge badge-red">Storniert</span>
                  <span v-if="reservation.status == 'confirmed'" class="badge badge-green">Bestätigt</span>
                </td>
                <td>{{ reservation.created_at }}</td>
                <td>{{ reservation.pickup_timestamp }}<br>{{ reservation.return_timestamp }}</td>
                <td>
                  <span v-if="reservation.vehicle">{{ reservation.vehicle.license_plate }}</span>
                </td>
                <td>0,00 €</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';

export default {
  name: 'admin_reservations',
  data () {
    return {
      reservations: [],
    }
  },
  methods: {
    get_data() {

      var that = this;
      axios.get(process.env.VUE_APP_BASE_API+'/v1/reservations', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        response.data.reservations.forEach(value => {
          that.reservations.push({
            status: value.status,
            created_at: moment(value.created_at).format("DD.MM.YYYY hh:mm"),
            pickup_timestamp: moment(value.pickup_timestamp).format("DD.MM.YYYY hh:mm"),
            return_timestamp: moment(value.return_timestamp).format("DD.MM.YYYY hh:mm"),
            vehicle: value.vehicle,
            user: value.user
          })
        });

      })
      .catch(error => {
        console.log(error);
      })

    },
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

</style>
